import './about.css';
import { ReactComponent as IconWeb } from './icons/web.svg';
import { ReactComponent as IconBack } from './icons/back.svg';
import { ReactComponent as IconData } from './icons/data.svg';
import { ReactComponent as IconCloud } from './icons/cloud.svg';
import { ReactComponent as IconPoint } from './icons/point.svg';
import {
    Container, Row, Col, Button, Navbar
} from 'reactstrap';

const About = () => {
    return (
        <Container className='about-container pt-5' id='about'>
            <Row>
                <Col lg={5} className='col-title'>
                    <h1 className='col-title-h1'>A PROPOS DE MOI</h1>
                    <p>
                        <span><IconPoint />Passionnée</span>
                        <span><IconPoint />Déterminéé</span>
                        <span><IconPoint />Curieuse</span>
                    </p>
                    <a href='../../file/MonCV.pdf' download>
                        <Button className='col-title-btn'>Télecharger CV</Button>
                    </a>
                    <div className='col-title-icon'>
                        <IconWeb width={40} height={50} />
                        <IconBack width={40} height={50} />
                        <IconData width={40} height={50} />
                        <IconCloud width={40} height={50} />
                    </div>
                </Col>

                <Col lg={7} className='pt-5 col-text'>
                    <p>
                        Avec plus de deux ans d'expérience en tant que développeuse backend, j'exerce également mes compétences en développement frontend. Passionnée par l'apprentissage continu et les défis techniques, je suis constamment en quête de nouvelles compétences à acquérir.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default About;