import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Brand } from './Image';
import {
  Container, Row, Col, Button, Navbar, Nav,
  NavbarBrand, NavItem, NavbarToggler, Collapse, Jumbotron
} from 'reactstrap';

const NavBar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <header>
      <Navbar fixed="top" className='navbar-section' style={{ paddingTop: '0' }}>
        <Container className='navbar-container'>
          <Row className="position-relative w-100">
            <Col lg={4} className="navbar-logo-col">
              <NavbarBrand className="d-flex logo" href="/">
                <div className='logo-text'>
                  Portfolio
                  <hr className='vr'></hr>
                </div>
                <Brand className='logo-img' />
              </NavbarBrand>
              <NavbarToggler onClick={toggleNavbar} className="d-md-none toggle-navbar" />
              <Collapse isOpen={!collapsed} navbar>
                <Nav className="d-block toggle-nav" navbar>
                  <NavItem className="nav-item">
                    <ScrollLink
                      className="font-weight-bold nav-link"
                      to="about"
                      smooth={true}
                      duration={500}
                      offset={-70}
                      onClick={toggleNavbar}
                    >
                      A propos de moi
                    </ScrollLink>
                  </NavItem>
                  <NavItem className="nav-item">
                    <ScrollLink
                      className="nav-link"
                      to="resume"
                      smooth={true}
                      duration={500}
                      offset={-70}
                      onClick={toggleNavbar}
                    >
                      Expériences
                    </ScrollLink>
                  </NavItem>

                  <NavItem className="nav-item">
                    <ScrollLink
                      className="nav-link"
                      to="blog"
                      smooth={true}
                      duration={500}
                      offset={-70}
                    >
                      Blog
                    </ScrollLink>
                  </NavItem>

                  <NavItem className="nav-item">
                    <ScrollLink
                      className="nav-link"
                      to="contact"
                      smooth={true}
                      duration={500}
                      offset={-70}
                      onClick={toggleNavbar}
                    >
                      Contact
                    </ScrollLink>
                  </NavItem>
                  <NavItem className="nav-item">
                    <Button className="nav-link btn-nav">Mon CV</Button>
                  </NavItem>
                </Nav>
              </Collapse>
            </Col>
            <Col lg={8} className="navbar-nav-col d-none d-md-block">
              <Nav className="d-flex nav" navbar>
                <NavItem className="nav-item">
                  <ScrollLink
                    className="font-weight-bold nav-link"
                    to="about"
                    smooth={true}
                    duration={500}
                    offset={-70}
                  >
                    A propos de moi
                  </ScrollLink>
                </NavItem>

                <NavItem className="nav-item">
                  <ScrollLink
                    className="nav-link"
                    to="resume"
                    smooth={true}
                    duration={500}
                    offset={-70}
                  >
                    Expériences
                  </ScrollLink>
                </NavItem>

                <NavItem className="nav-item">
                  <ScrollLink
                    className="nav-link"
                    to="blog"
                    smooth={true}
                    duration={500}
                    offset={-70}
                  >
                    Blog
                  </ScrollLink>
                </NavItem>
                <NavItem className="nav-item">
                  <ScrollLink
                    className="nav-link"
                    to="contact"
                    smooth={true}
                    duration={500}
                    offset={-70}
                  >
                    Contact
                  </ScrollLink>
                </NavItem>

                <NavItem className="nav-item">
                  <Button className="nav-link btn-nav" href="../../file/MonCV.pdf">Mon CV</Button>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavBar;
